import { useState } from 'react'

const useModal = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [modalData, setModalData] = useState({
		title: '',
		content: '',
	})

	const openModal = (title, content) => {
		setModalData({
			title,
			content,
		})
		setIsOpen(true)
	}

	const closeModal = () => {
		setIsOpen(false)
		setModalData({
			title: '',
			content: '',
		})
	}

	return {
		isOpen,
		openModal,
		closeModal,
		modalData,
	}
}

export default useModal
