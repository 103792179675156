import classNames from 'classnames'
import {
	CircleX,
	Earth,
	Expand,
	Heart,
	Images,
	MapPinHouse,
	Shrink,
	SlidersHorizontal,
	X,
} from 'lucide-react'
import React, { useState } from 'react'
import InputRange from '../InputRange/RangeSlider'
import styles from './DemoFilter.module.scss'

export default function DemoFilter({
	activeTab,
	setActiveTab,
	toggleFilter,
	rooms,
	setRooms,
	rangeValues,
	handleRangeChange,
	available,
	setAvailable,
	isFullScreen,
	handleFullScreenChange,
	handleResetFilter,
	resetFlag,
}) {
	const [showFilter, setShowFilter] = useState(true)

	const handleTabClick = tabName => {
		setActiveTab(tabName)
	}

	const toggleRoomSelection = num => {
		if (num === 0) {
			// Сбрасываем все выбранные комнаты
			setRooms([])
		} else {
			setRooms(
				prevRooms =>
					prevRooms.includes(num)
						? prevRooms.filter(room => room !== num) // Убираем комнату, если она уже выбрана
						: [...prevRooms, num] // Добавляем комнату, если она не выбрана
			)
		}
	}

	const stopPropagation = e => e.stopPropagation()

	return (
		<div>
			<div className={styles.filter}>
				<div
					className={classNames(styles.filtersTab, {
						[styles.active]: activeTab === 'overview',
					})}
					onClick={() => handleTabClick('overview')}
				>
					<Earth />
					<p>Обзор</p>
				</div>
				<div
					className={classNames(styles.filtersTab, {
						[styles.active]: activeTab === 'filters',
					})}
					onClick={() => handleTabClick('filters')}
				>
					<SlidersHorizontal />
					<p>Фильтры</p>
				</div>

				<div
					className={classNames(styles.filtersTab, styles.disabled, {
						[styles.active]: activeTab === 'gallery',
					})}
					onClick={() => handleTabClick('gallery')}
				>
					<Images />
					<p>Галерея</p>
				</div>
				<div
					className={classNames(styles.filtersTab, styles.disabled, {
						[styles.active]: activeTab === 'environment',
					})}
					onClick={() => handleTabClick('environment')}
				>
					<MapPinHouse />
					<p>Окружение</p>
				</div>
				<div
					className={classNames(styles.filtersTab, styles.disabled, {
						[styles.active]: activeTab === 'favorites',
					})}
					onClick={() => handleTabClick('favorites')}
				>
					<Heart />
					<p>Избранное</p>
				</div>
				<div className={styles.fullScreenTab}>
					<button onClick={handleFullScreenChange}>
						{isFullScreen ? <Shrink /> : <Expand />}
					</button>
				</div>
			</div>
			<div
				className={classNames(
					styles.expandableBlock,
					activeTab !== 'filters' || !showFilter ? styles.hidden : ''
				)}
			>
				{/* <div className={classNames(styles.closeExpand)}>
					<ChevronUp
						className={showFilter ? styles.close : ''}
						onClick={() => setShowFilter(prev => !prev)}
					/>
				</div> */}
				<div className={styles.rooms}>
					{/* <HousePlus /> */}
					<p>Количество комнат</p>
					<div className={styles.numberButtons}>
						{[0, 1, 2, 3, 4, 5].map(num => (
							<button
								key={num}
								onClick={() => toggleRoomSelection(num)}
								className={rooms.includes(num) ? styles.active : ''}
							>
								{num === 0 ? <X /> : num}
							</button>
						))}
					</div>
				</div>
				<div className={styles.sliders}>
					<div className={classNames(styles.wrapRange, styles.surface)}>
						<div className={classNames(styles.labelSurface, styles.label)}>
							<p>
								Площадь, м<sup>2</sup>
							</p>
						</div>

						<InputRange
							min={60}
							max={240}
							step={1}
							values={rangeValues.surfaceRange}
							resetFlag={resetFlag}
							onChange={values => handleRangeChange('surfaceRange', values)}
						/>
					</div>
					<div className={classNames(styles.wrapRange, styles.floor)}>
						<div className={classNames(styles.labelFloor, styles.label)}>
							<p>Этаж</p>
						</div>

						<InputRange
							min={1}
							max={10}
							step={1}
							values={rangeValues.floorRange}
							resetFlag={resetFlag}
							onChange={values => handleRangeChange('floorRange', values)}
						/>
					</div>
					<div className={classNames(styles.wrapRange, styles.cost)}>
						<div className={classNames(styles.labelCost, styles.label)}>
							<p>Цена, млн. ₽</p>
						</div>
						<InputRange
							values={rangeValues.costRange}
							resetFlag={resetFlag}
							onChange={values => handleRangeChange('costRange', values)}
						/>
					</div>
				</div>
				<div className={styles.reset}>
					<div className={styles.wrapBtnReset}>
						<button onClick={() => handleResetFilter()}>
							<CircleX className='mr-1' />
							Cбросить
						</button>
					</div>
				</div>
				{/* <div className={styles.available}>
					<p>Доступность</p>
					<div className={styles.availableButtons}>
						<button onClick={() => setAvailable(null)}>
							<CircleEllipsis />
						</button>
						<button onClick={() => setAvailable(true)}>
							<CircleCheck />
						</button>
						<button onClick={() => setAvailable(false)}>
							<CircleX />
						</button>
					</div>
				</div> */}
			</div>
		</div>
	)
}
