import React, { useEffect, useState } from 'react'
import { Range } from 'react-range'

const InputRange = ({ min = 5, max = 10, step = 1, onChange, values }) => {
	const [sliderValues, setSliderValues] = useState([min, max])

	useEffect(() => {
		setSliderValues(values)
	}, [values])

	return (
		<div className='w-full'>
			<Range
				values={sliderValues}
				step={step}
				min={min}
				max={max}
				onChange={newValues => onChange(newValues)}
				renderTrack={({ props, children }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: '6px',
							width: '100%',
							background: 'linear-gradient(to right, #3b82f6 0%, #3b82f6 100%)',
						}}
						className='relative'
					>
						{children}
					</div>
				)}
				renderThumb={({ index, props, isDragged }) => (
					<div
						{...props}
						key={props.key}
						style={{
							...props.style,
							height: '0px',
							width: 'auto',
							aspectRatio: '1/1',
							borderRadius: '4px',
							backgroundColor: '#FFF',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							boxShadow: '0px 2px 6px #AAA',
						}}
					>
						<div
							style={{
								position: 'absolute',
								top: '0px',
								transform: 'translateY(-50%)',
								color: isDragged ? '' : '#000000',
								fontSize: '14px',
								fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
								padding: '2px 4px',
								borderRadius: '50px',
								backgroundColor: isDragged ? '#548BF4' : '#ffffff',
							}}
						>
							{sliderValues[index].toFixed(0)}
						</div>
						<div
							style={{
								height: '16px',
								width: '5px',
								backgroundColor: isDragged ? '#548BF4' : '#CCC',
							}}
						/>
					</div>
				)}
			/>

			<div className='flex justify-between gap-1 mt-2 text-white text-[14px]'>
				<span>{min}</span>
				<span>{Math.floor(min + (max - min) * 0.2)}</span>
				<span>{Math.floor(min + (max - min) * 0.4)}</span>
				<span>{Math.floor(min + (max - min) * 0.6)}</span>
				<span>{Math.floor(min + (max - min) * 0.8)}</span>
				<span>{max}</span>
			</div>
		</div>
	)
}

export default InputRange
