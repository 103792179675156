import './App.css'
import About from './blocks/About'
import Banner from './blocks/Banner'
import CommercialOffer from './blocks/CommercialOffer'
import Demo from './blocks/Demo/Demo'
import Form from './blocks/Form'
import Portfolio from './blocks/Portfolio'
import Steps from './blocks/Steps/Steps'
import Tasks from './blocks/Tasks'
import { Footer, Header, Modal } from './components'
import useModal from './Hooks'

function App() {
	// openModal принимает title и content (содержит верстку)
	const { isOpen, closeModal, modalData, openModal } = useModal()

	return (
		<div className='App'>
			<Header openModalWithData={openModal} />

			<Banner />
			<About />
			<Demo openModalWithData={openModal} />
			<CommercialOffer />
			<Portfolio />
			{/* <Video /> */}
			<Tasks openModalWithData={openModal} />
			<Steps />
			<Form />
			<Footer openModalWithData={openModal} />
			<Modal
				isOpen={isOpen}
				onClose={closeModal}
				title={modalData.title}
				content={modalData.content}
			/>
		</div>
	)
}

export default App
