import React from 'react'

export default function Steps() {
	return (
		<div className='flex flex-col items-center gap-10'>
			<h2 className='mt-12 text-[32px] uppercase'>Этапы работы</h2>
			<ul className='timeline timeline-snap-icon max-md:timeline-compact timeline-vertical'>
				<li>
					<div className='timeline-middle'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'
							fill='currentColor'
							className='h-5 w-5'
						>
							<path
								fillRule='evenodd'
								d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
								clipRule='evenodd'
							/>
						</svg>
					</div>
					<div className='timeline-start mb-10 md:text-center'>
						<div className='timeline-box text-lg font-bold bg-[rgb(245,101,12)] w-fit mx-auto'>
							Отвечаем на заявку
						</div>
						<p className='max-w-md'>
							Уточняем требования и цели проекта. Предоставляем предварительную
							оценку сроков и бюджета.
						</p>
					</div>

					<hr />
				</li>
				<li>
					<hr />
					<div className='timeline-middle'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'
							fill='currentColor'
							className='h-5 w-5'
						>
							<path
								fillRule='evenodd'
								d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
								clipRule='evenodd'
							/>
						</svg>
					</div>
					<div className='timeline-end mb-10 md:text-center'>
						<div className='timeline-box text-lg font-bold bg-[rgb(245,101,12)] w-fit mx-auto'>
							Составляем задание
						</div>
						<p className='max-w-md'>
							Определяем объем проекта и его цели. Формируем список необходимых
							задач и результатов. Создаем детализированный план работ и график
							выполнения.
						</p>
					</div>
					<hr />
				</li>
				<li>
					<hr />
					<div className='timeline-middle'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'
							fill='currentColor'
							className='h-5 w-5'
						>
							<path
								fillRule='evenodd'
								d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
								clipRule='evenodd'
							/>
						</svg>
					</div>
					<div className='timeline-start mb-10 md:text-center'>
						<div className='timeline-box text-lg font-bold bg-[rgb(245,101,12)] w-fit mx-auto'>
							Утверждаем макет проекта
						</div>
						<p className='max-w-md'>
							Разрабатываем и утверждаем визуальный концепт и функциональный
							прототип.
						</p>
					</div>
					<hr />
				</li>
				<li>
					<hr />
					<div className='timeline-middle'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'
							fill='currentColor'
							className='h-5 w-5'
						>
							<path
								fillRule='evenodd'
								d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
								clipRule='evenodd'
							/>
						</svg>
					</div>
					<div className='timeline-end mb-10 md:text-center'>
						<div className='timeline-box text-lg font-bold bg-[rgb(245,101,12)] w-fit mx-auto'>
							Выполняем основные работы
						</div>
						<p className='max-w-md'>
							Реализуем интерактивную архитектурную визуализацию. Разрабатываем
							интерфейс и функционал. Тестируем и доводим до совершенства.
						</p>
					</div>
					<hr />
				</li>
				<li>
					<hr />
					<div className='timeline-middle'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'
							fill='currentColor'
							className='h-5 w-5'
						>
							<path
								fillRule='evenodd'
								d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
								clipRule='evenodd'
							/>
						</svg>
					</div>
					<div className='timeline-start mb-10 md:text-center'>
						<div className='timeline-box text-lg font-bold bg-[rgb(245,101,12)] w-fit mx-auto'>
							Запускаем сайт
						</div>
						<p className='max-w-md'>
							Публикуем сайт. Настраиваем аналитические и трекинговые
							инструменты.
						</p>
					</div>
				</li>
			</ul>
		</div>
	)
}
