import React from 'react'
import styles from './Banner.module.scss'

const Banner = () => {
	return (
		<div className={styles.banner}>
			<img className={styles.bannerImg} src='images/banner.webp' />
			<h1 className={styles.bannerTitle}>
				<span>
					ArtTell<br></br>студия интерактивной визуализации
				</span>
				Воплощаем будущее
			</h1>
		</div>
	)
}

export default Banner
