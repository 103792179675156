import React from 'react'

const CommercialOffer = () => {
	return (
		<div className='offer'>
			<div className='commercialOfferImg'>
				<img
					className='commercialOfferBanner'
					src='images/commercBlockWide.webp'
				/>
			</div>

			<div className='commercialOffer'>
				<h2 className='commercialOfferTitle'>
					ИДЕАЛЬНЫЙ СПОСОБ ЗАИНТЕРЕСОВАТЬ САМЫХ ИЗБИРАТЕЛЬНЫХ КЛИЕНТОВ
				</h2>

				<div className='commercialOfferText'>
					<p className='text'>
						Презентуйте жилую и коммерческую недвижимость с помощью удобного
						веб-интерфейса на сенсорном дисплее.Забудьте о громоздких макетах —
						интерактивная презентация у вас под рукой, будь то встреча с
						клиентом на его территории, офис продаж или выставка.
					</p>
				</div>
			</div>
		</div>
	)
}

export default CommercialOffer
