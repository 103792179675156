import { AtSign, Phone } from 'lucide-react'
import React from 'react'
import Logo from '../Header/logo'
import {
	ContactInfo,
	Copyright,
	FooterContainer,
	FooterContent,
	LogoWrapper,
} from './styled'

const Footer = () => {
	const currentYear = new Date().getFullYear()

	return (
		<FooterContainer>
			<FooterContent>
				<ContactInfo>
					<a
						href='mailto:arttell.studio@yandex.ru'
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<AtSign style={{ marginRight: '0.5rem' }} />{' '}
						arttell.studio@yandex.ru
					</a>
					<a
						href='tel:+7 (964) 948 49 09'
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<Phone style={{ marginRight: '0.5rem' }} />
						+7 (964) 948 49 09
					</a>
					{/* <Socials>
						<a>
							<Vk fill='#fff' />
						</a>
						<a>
							<Telegram fill='#fff' />
						</a>
					</Socials> */}
					{/* <a href='#'>г. Москва, ул. Пушкина, д. 10</a> */}
				</ContactInfo>
				<LogoWrapper>
					<Logo />
				</LogoWrapper>
				<Copyright>
					<a href='politConf.pdf' target='_blank' rel='noopener noreferrer'>
						Пользовательское соглашение
					</a>
					ArtTell.Studio &copy; {currentYear}. Все права защищены.
				</Copyright>
			</FooterContent>
		</FooterContainer>
	)
}

export default Footer
