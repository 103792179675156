import classNames from 'classnames'
import React, { useState } from 'react'
import styled from 'styled-components'
import Video from '../Video/Video'
import styles from './Portfolio.module.scss'
import PortfolioPanel from './styled'

const ImageContainer = styled.div`
	border-radius: 8px;
	cursor: pointer;

	&.active {
		transform: scale(1.2);
		z-index: 2;
	}

	&.blur {
		filter: blur(3px);
	}
	transition: transform 0.1s ease-in-out;
`

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const images = {
	'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий':
		[
			'/images/landscapeDesign/4.webp',
			'/images/landscapeDesign/5.webp',
			'/images/landscapeDesign/6.webp',
			'/images/landscapeDesign/7.webp',
			'/images/landscapeDesign/8.webp',
			'/images/landscapeDesign/9.webp',
			'/images/landscapeDesign/10.webp',
		],
	'Дизайн интерьера + спецификация': [
		'/images/designInterier/1.webp',
		'/images/designInterier/2.webp',
		'/images/designInterier/3.webp',
		'/images/designInterier/4.webp',
		'/images/designInterier/5.webp',
		'/images/designInterier/6.webp',
		'/images/designInterier/7.webp',
	],
}

const classesGrid = {
	'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий':
		[
			'col-span-1 row-start-1 col-start-1',
			'col-span-2 row-start-1 col-start-2',
			'col-span-1 row-start-1 col-start-4',
			'col-span-1 row-start-2 col-start-1',
			'col-span-1 row-start-2 col-start-2',
			'col-span-1 row-start-2 col-start-3',
			'col-span-1 row-start-2 col-start-4',
		],
	'Дизайн интерьера + спецификация': [
		'col-span-1 row-start-1 col-start-1',
		'col-span-1 row-start-2 col-start-1',
		'col-span-1 row-start-1 col-start-2',
		'col-span-1 row-start-2 col-start-2',
		'col-span-1 row-start-1 col-start-3',
		'col-span-1 row-start-2 col-start-3',
		'row-span-2 row-start-1 col-start-4',
	],
}

const Portfolio = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedFilter, setSelectedFilter] = useState(
		'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий'
	)
	const [hoveredIndex, setHoveredIndex] = useState(null)

	const togglePanel = () => {
		setIsOpen(prevState => !prevState)
	}

	const handleFilterChange = filter => {
		setSelectedFilter(prevFilter => (prevFilter === filter ? null : filter))
	}

	const renderContent = () => {
		switch (selectedFilter) {
			case 'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий':
				return (
					<div className='h-auto lg:h-[450px] flex flex-col md:gap-4 justify-center items-center lg:flex-row lg:items-start'>
						<div className='w-full flex flex-wrap gap-2 lg:w-[50cqw]'>
							<h3>
								ЭФФЕКТИВНОЕ РЕШЕНИЕ ДЛЯ ПРЕЗЕНТАЦИИ АРХИТЕКТУРНЫХ ПРОЕКТОВ
							</h3>
							<p>
								Проводите впечатляющие презентации архитектурных проектов в
								интерактивном формате, позволяющем выделить ваше предложение.
							</p>
							<p>
								Интерактивная 3D-модель с фотореалистичной детализацией создаст
								полное погружение и выгодно подчеркнет все достоинства вашего
								проекта.
							</p>
						</div>
						<div className='w-auto lg:max-w-[50%] h-full rounded-lg overflow-hidden mb-4 md:mb-0 object-contain aspect-video'>
							<div className='h-full w-full grid grid-cols-4 grid-rows-2'>
								{images[selectedFilter].map((src, index) => (
									<ImageContainer
										key={src}
										className={classNames(classesGrid[selectedFilter][index], {
											active: hoveredIndex === index,
											blur: hoveredIndex !== null && hoveredIndex !== index,
										})}
										onMouseEnter={() => setHoveredIndex(index)}
										onMouseLeave={() => setHoveredIndex(null)}
									>
										<StyledImage src={src} alt='Architecture 1' />
									</ImageContainer>
								))}
							</div>
						</div>
					</div>
				)
			case 'Виртуальные 3D туры':
				return (
					<div className='h-auto lg:h-[450px] flex flex-col md:gap-4 justify-center items-center lg:flex-row lg:items-start'>
						<div className='w-full flex flex-wrap gap-2 lg:w-[50cqw]'>
							<p>
								3D туры представляют собой интерактивные виртуальные экскурсии,
								которые позволяют вам осматривать объекты в формате кругового
								обзора на 360 градусов.
							</p>
							<p>
								Будь то квартира, офис или туристическая достопримечательность —
								вы можете изучать детали, перемещаться по помещениям и получать
								полное представление о месте, не выходя из дома.
							</p>
							<p>
								Идеально подходит для продажи недвижимости и маркетинга.
								Откройте новые горизонты с 3D турами и сделайте ваш выбор проще
								и увлекательнее!
							</p>
						</div>
						<div className='w-auto h-full rounded-lg overflow-hidden mb-4 md:mb-0 object-contain'>
							<img
								src={'/images/3dtour.webp'}
								className='w-full h-full object-contain'
							></img>
						</div>
					</div>
				)
			case 'Фотореалистичные маркетинговые изображения и видео':
				return (
					<div className='h-auto lg:h-[450px] flex flex-col md:gap-4 justify-center items-center lg:flex-row lg:items-start'>
						<div className='w-full flex flex-wrap gap-2 lg:w-[50cqw]'>
							<p>
								Фотореалистичные маркетинговые изображения и видео — мощный
								инструмент для привлечения клиентов, представляющий продукт или
								услугу в выгодном свете. Наши высококачественные визуализации
								передают каждую деталь, создавая эффект реального присутствия и
								погружая зрителя в атмосферу бренда.
							</p>
							<p>
								Идеально подходят для рекламы, презентаций и социальных сетей,
								фотореалистичные изображения и видео помогают выделиться среди
								конкурентов и вызвать доверие. Поднимите свой маркетинг на новый
								уровень с яркими и запоминающимися визуальными решениями!
							</p>
						</div>
						<Video />
					</div>
				)
			case 'Дизайн интерьера + спецификация':
				return (
					<div className='h-auto lg:h-[450px] flex flex-col md:gap-4 justify-center items-center lg:flex-row lg:items-start'>
						<div className='w-full flex flex-wrap gap-2 lg:w-[30cqw]'>
							<p>
								Создайте пространство своей мечты с профессиональным дизайном
								интерьера! Мы предлагаем индивидуальные решения, которые
								отражают ваш стиль и удовлетворяют все потребности. Наша команда
								экспертов разрабатывает уникальные концепции, учитывая
								функциональность, эстетику и последние тенденции.
							</p>
						</div>
						<div className='w-auto h-full rounded-lg overflow-hidden mb-4 md:mb-0 object-contain aspect-video'>
							<div className='h-full w-full grid grid-cols-4 grid-rows-2'>
								{images[selectedFilter].map((src, index) => (
									<ImageContainer
										key={src}
										className={classNames(classesGrid[selectedFilter][index], {
											active: hoveredIndex === index,
											blur: hoveredIndex !== null && hoveredIndex !== index,
										})}
										onMouseEnter={() => setHoveredIndex(index)}
										onMouseLeave={() => setHoveredIndex(null)}
									>
										<StyledImage src={src} alt='Architecture 1' />
									</ImageContainer>
								))}
							</div>
						</div>
					</div>
				)
			case 'Разработка и продвижение веб-сервисов для девелоперов и застройщиков':
				return (
					<div className='h-auto lg:h-[450px] flex flex-col md:gap-4 justify-center items-center lg:flex-row lg:items-start'>
						<div className='w-full flex flex-wrap gap-2 lg:w-[50cqw]'>
							<p>
								Увеличьте свои продажи и упростите процессы с помощью разработки
								и продвижения специализированных веб-сервисов для девелоперов и
								застройщиков! Мы создаем современные, функциональные и
								адаптивные сайты, которые не только привлекают внимание, но и
								эффективно конвертируют посетителей в клиентов.
							</p>
							<p>
								<ul className='list-disc pl-5 space-y-1'>
									<li>
										Индивидуальный дизайн: Уникальные решения, отражающие ваш
										бренд
									</li>
									<li>
										Интерактивные элементы: 3D туры, виртуальные показы и
										онлайн-бронирование
									</li>
									<li>
										SEO-оптимизация: Повышение видимости вашего сайта в
										поисковых системах
									</li>
									<li>
										Маркетинговые стратегии: Комплексный подход к продвижению
										для достижения максимальных результатов
									</li>
								</ul>
							</p>
							<p>
								Дайте вашему бизнесу мощный импульс с нашими веб-сервисами и
								оставайтесь на шаг впереди конкурентов!
							</p>
						</div>
						<div className='w-auto h-full rounded-lg overflow-hidden mb-4 md:mb-0 object-contain'>
							<img
								src={'/images/webDev.webp'}
								className='w-full h-full object-contain'
							></img>
						</div>
					</div>
				)
			default:
				return (
					<div className='h-[400px] flex md:gap-4 justify-center items-center md:items-start'>
						<div className='w-auto h-full rounded-lg overflow-hidden mb-4 md:mb-0 object-contain'>
							<img
								src={'/images/services1.webp'}
								className='w-full h-full object-contain'
							></img>
						</div>
					</div>
				)
		}
	}

	return (
		<div
			className={
				'relative h-auto flex flex-col items-center shadow-md gap-4 md:flex-row md:items-start p-4 overflow-hidden'
			}
		>
			<PortfolioPanel
				onClick={togglePanel}
				isOpen={isOpen}
				selectedFilter={selectedFilter}
				onFilterChange={handleFilterChange}
			>
				Наши услуги
			</PortfolioPanel>
			<div className={'w-full h-full flex flex-col gap-4 pl-8'}>
				<h2 className={styles.portfolioTitle}>мы выполняем:</h2>
				{selectedFilter ? (
					renderContent()
				) : (
					<div className='h-[400px] flex md:gap-4 justify-center items-start '>
						<ul className='list-disc pl-5 space-y-1'>
							<li>3D визуализация архитектуры;</li>
							<li>Визуализация жилых комплексов;</li>
							<li>Визуализация дома;</li>
							<li>Визуализация коттеджа;</li>
							<br></br>
							<li>3D моделирование здания;</li>
							<li>Рекламную графику для печати;</li>
							<li>Визуализация 3D планировок;</li>
							<li>Дизайн типовых квартир ЖК;</li>
						</ul>
						<div className='w-auto h-full rounded-lg overflow-hidden mb-4 md:mb-0 object-contain'>
							<img
								src={'/images/portfolio.webp'}
								className='w-full h-full object-contain'
							></img>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Portfolio
