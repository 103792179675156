import styled, { css } from 'styled-components'

// Проп type можно передавать явно, если не передан — значение по умолчанию "button"
const Button = styled.button.attrs(({ type = 'button', disabled }) => ({
	type, // "button" или "submit", или другое значение, если нужно
	disabled,
}))`
	user-select: none;
	border-radius: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	padding: 10px 22px;
	letter-spacing: 0.36px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	width: fit-content;

	${({ disabled }) =>
		disabled
			? css`
					background-color: #ccc;
					color: #666;
					pointer-events: none;
			  `
			: css`
					background-color: rgb(245, 101, 12);
					color: #ffffff;

					&:hover {
						background-color: #e25a00;
					}
			  `}
`

export default Button
