import classNames from 'classnames'
import { Heart, X, ZoomIn } from 'lucide-react'
import React, { useRef, useState } from 'react'
import styles from './DraggableModal.module.scss'

const DraggableModal = ({ onClose, data, openModalWithData }) => {
	const modalRef = useRef(null)
	// const [isDragging, setIsDragging] = useState(false)
	const [position, setPosition] = useState({ x: 0, y: 0 })

	const handlePlanZoom = () => {
		const content = (
			<div className={styles.planZoom}>
				<img src={'/Demo/plane.jpg'} alt='' />
			</div>
		)
		openModalWithData('План', React.cloneElement(content))
	}

	// const [initialMousePosition, setInitialMousePosition] = useState({
	// 	x: 0,
	// 	y: 0,
	// })
	// const [showExtraBlock, setShowExtraBlock] = useState(false)

	// const handleMouseDown = e => {
	// 	setIsDragging(true)
	// 	setInitialMousePosition({
	// 		x: e.clientX - position.x,
	// 		y: e.clientY - position.y,
	// 	})
	// }

	// const handleMouseMove = e => {
	// 	if (isDragging) {
	// 		setPosition({
	// 			x: e.clientX - initialMousePosition.x,
	// 			y: e.clientY - initialMousePosition.y,
	// 		})
	// 	}
	// }

	// const handleMouseUp = () => {
	// 	setIsDragging(false)
	// }

	// useEffect(() => {
	// 	if (isDragging) {
	// 		document.addEventListener('mousemove', handleMouseMove)
	// 		document.addEventListener('mouseup', handleMouseUp)
	// 	} else {
	// 		document.removeEventListener('mousemove', handleMouseMove)
	// 		document.removeEventListener('mouseup', handleMouseUp)
	// 	}

	// 	return () => {
	// 		document.removeEventListener('mousemove', handleMouseMove)
	// 		document.removeEventListener('mouseup', handleMouseUp)
	// 	}
	// }, [isDragging])

	return (
		<div
			ref={modalRef}
			className={styles.modal}
			style={{
				top: `${position.y}px`,
				right: `${position.x}px`,
			}}
		>
			<div className={styles.main}>
				<div className={styles.head}>
					<div className={styles.line}></div>
					<div className={styles.title}>
						<Heart className={styles.like} />
						<h2>{data.id}</h2>
					</div>
					<button onClick={onClose} className={styles.close}>
						<X />
					</button>
				</div>
				<div className={styles.center}>
					<div className={styles.wrapOptions}>
						<p>Этаж:</p>
						<p>{data.floor}</p>
					</div>
					<div className={styles.wrapOptions}>
						<p>Комнаты:</p>
						<p>{data.rooms}</p>
					</div>
					<div className={styles.wrapOptions}>
						<p>
							Плошадь, м<sup>2</sup>:
						</p>
						<p>{data.surface}</p>
					</div>
					<div className={styles.wrapOptions}>
						<p>Доступность:</p>
						<p>{data.availability ? 'Да' : 'Нет'}</p>
					</div>
					<div className={classNames(styles.wrapOptions, styles.price)}>
						<p>Цена, млн.₽ :</p>
						<p>{data.price}</p>
					</div>
				</div>
				{/* <div className={styles.footer}>
					<button onClick={() => setShowExtraBlock(true)}>План квартиры</button>
				</div> */}
			</div>

			<div className={styles.extraBlock}>
				<div className={styles.headExtra}>
					<div className={styles.line}></div>
					<div className={styles.title}>
						<h3>План квартиры</h3>
					</div>
					{/* <button
						onClick={() => setShowExtraBlock(false)}
						className={styles.closeExtra}
					>
						<ChevronLeft />
					</button> */}
				</div>
				<div className={styles.contentExtra}>
					<img src={'/Demo/plane.jpg'} alt='' />
					<button className={styles.btnZoom} onClick={handlePlanZoom}>
						<ZoomIn />
					</button>
				</div>
			</div>
		</div>
	)
}

export default DraggableModal
