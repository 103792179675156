import { ErrorMessage, Field, Form, Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	button {
		margin-top: 10px;
		padding: 10px 20px;
		background-color: #fff;
		border: 1px solid #ccc;
		color: #000;
		border-radius: 4px;
		cursor: pointer;

		&:hover {
			background-color: #141414;
			color: #fff;
		}

		transition: all 0.1s ease-in;
	}
`

const InputField = styled(Field)`
	display: block;
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
`

const ErrorText = styled.div`
	color: red;
	margin-bottom: 10px;
`

const CallForm = ({ onClose }) => {
	const validationSchema = Yup.object({
		name: Yup.string().required('Введите имя'),
		phone: Yup.string()
			.required('Введите телефон')
			.matches(
				/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
				'Неверный формат телефона'
			),
	})

	const handleSubmit = (values, { setSubmitting }) => {
		setTimeout(() => {
			alert(JSON.stringify(values, null, 2))
			setSubmitting(false)
			onClose()
		}, 400)
	}

	return (
		<Formik
			initialValues={{ name: '', phone: '' }}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<FormWrapper>
						<label htmlFor='name'>Ваше имя</label>
						<InputField type='text' name='name' placeholder='Ваше имя' />
						<ErrorMessage name='name' component={ErrorText} />

						<label htmlFor='phone'>Ваш телефон</label>
						<InputField type='tel' name='phone' placeholder='Ваш телефон' />
						<ErrorMessage name='phone' component={ErrorText} />

						<button type='submit' disabled={isSubmitting}>
							Отправить
						</button>
					</FormWrapper>
				</Form>
			)}
		</Formik>
	)
}

export default CallForm
