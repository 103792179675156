export const TASKS = [
	{
		title: 'экономия',
		desc: 'Вам не требуется дорогостоящее и громоздкое оборудование, для полноценной презентации достаточно лишь подключения к интернету.',
		foolDesk:
			'Вам не требуется дорогостоящее и громоздкое оборудование, для полноценной презентации достаточно лишь подключения к интернету.',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
	{
		title: 'ДОСТУПНОСТЬ',
		desc: 'Интерактивная визуализация позволяет улучшить коммуникации с удаленными покупателями, которые могут самостоятельно осматривать Ваш проект',
		foolDesk:
			'Интерактивная визуализация позволяет улучшить коммуникации с удаленными покупателями, которые могут самостоятельно осматривать Ваш проект',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
	{
		title: 'УДОБСТВО',
		desc: 'Интуитивный интерфейс не требует дополнительного обучения, все необходимые функции буквально под рукой',
		foolDesk:
			'Интуитивный интерфейс не требует дополнительного обучения, все необходимые функции буквально под рукой',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
	{
		title: 'СКОРОСТЬ',
		desc: 'Наши решения не требуют ожидания долгой загрузки, Ваш проект доступен с момента входа на сайт',
		foolDesk:
			'Наши решения не требуют ожидания долгой загрузки, Ваш проект доступен с момента входа на сайт',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
]

export const Tags = [
	{
		id: 1,
		name: 'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий',
	},
	{
		id: 2,
		name: 'Виртуальные 3D туры',
	},
	{
		id: 3,
		name: 'Фотореалистичные маркетинговые изображения и видео',
	},
	{
		id: 4,
		name: 'Дизайн интерьера + спецификация',
	},
	{
		id: 5,
		name: 'Разработка и продвижение веб-сервисов для девелоперов и застройщиков',
	},
]

export const ApartsId = [
	{
		id: 'Demo/svg/1.svg',
		elements: [
			{
				id: 'Слой_3_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 87,
				floor: 10,
			},
			{
				id: 'Слой_4_',
				rooms: 2,
				price: 6,
				available: true,
				surface: 122,
				floor: 10,
			},
			{
				id: 'Слой_5_',
				rooms: 3,
				price: 7,
				available: false,
				surface: 180,
				floor: 10,
			},
			{
				id: 'Слой_6_',
				rooms: 4,
				price: 8,
				available: true,
				surface: 203,
				floor: 9,
			},
			{
				id: 'Слой_7_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 161,
				floor: 9,
			},
			{
				id: 'Слой_8_',
				rooms: 1,
				price: 10,
				available: true,
				surface: 93,
				floor: 9,
			},
			{
				id: 'Слой_9_',
				rooms: 2,
				price: 5,
				available: true,
				surface: 143,
				floor: 8,
			},
			{
				id: 'Слой_10',
				rooms: 3,
				price: 6,
				available: false,
				surface: 193,
				floor: 8,
			},
			{
				id: 'Слой_11',
				rooms: 4,
				price: 7,
				available: true,
				surface: 163,
				floor: 8,
			},
			{
				id: 'Слой_12',
				rooms: 5,
				price: 8,
				available: true,
				surface: 173,
				floor: 7,
			},
			{
				id: 'Слой_13',
				rooms: 1,
				price: 9,
				available: true,
				surface: 103,
				floor: 7,
			},
			{
				id: 'Слой_14',
				rooms: 2,
				price: 10,
				available: true,
				surface: 133,
				floor: 7,
			},
			{
				id: 'Слой_15',
				rooms: 3,
				price: 5,
				available: true,
				surface: 213,
				floor: 6,
			},
			{
				id: 'Слой_16',
				rooms: 4,
				price: 6,
				available: true,
				surface: 183,
				floor: 6,
			},
			{
				id: 'Слой_17',
				rooms: 5,
				price: 7,
				available: false,
				surface: 153,
				floor: 6,
			},
			{
				id: 'Слой_18',
				rooms: 1,
				price: 8,
				available: true,
				surface: 113,
				floor: 5,
			},
			{
				id: 'Слой_19',
				rooms: 2,
				price: 9,
				available: true,
				surface: 163,
				floor: 5,
			},
			{
				id: 'Слой_20',
				rooms: 3,
				price: 10,
				available: true,
				surface: 193,
				floor: 5,
			},
			{
				id: 'Слой_21',
				rooms: 4,
				price: 5,
				available: true,
				surface: 173,
				floor: 4,
			},
			{
				id: 'Слой_22',
				rooms: 5,
				price: 6,
				available: true,
				surface: 157,
				floor: 4,
			},
			{
				id: 'Слой_23',
				rooms: 1,
				price: 7,
				available: true,
				surface: 105,
				floor: 4,
			},
			{
				id: 'Слой_24',
				rooms: 2,
				price: 8,
				available: true,
				surface: 135,
				floor: 3,
			},
			{
				id: 'Слой_25',
				rooms: 3,
				price: 9,
				available: false,
				surface: 203,
				floor: 3,
			},
			{
				id: 'Слой_26',
				rooms: 4,
				price: 10,
				available: true,
				surface: 163,
				floor: 3,
			},
			{
				id: 'Слой_27',
				rooms: 5,
				price: 5,
				available: true,
				surface: 183,
				floor: 2,
			},
			{
				id: 'Слой_28',
				rooms: 1,
				price: 6,
				available: true,
				surface: 113,
				floor: 2,
			},
			{
				id: 'Слой_29',
				rooms: 2,
				price: 7,
				available: true,
				surface: 133,
				floor: 2,
			},
			{
				id: 'Слой_30',
				rooms: 3,
				price: 8,
				available: true,
				surface: 193,
				floor: 1,
			},
			{
				id: 'Слой_31',
				rooms: 4,
				price: 9,
				available: false,
				surface: 213,
				floor: 1,
			},
			{
				id: 'Слой_32',
				rooms: 5,
				price: 10,
				available: true,
				surface: 173,
				floor: 1,
			},
			{
				id: 'Слой_33',
				rooms: 1,
				price: 5,
				available: true,
				surface: 103,
				floor: 8,
			},
			{
				id: 'Слой_34',
				rooms: 2,
				price: 6,
				available: true,
				surface: 135,
				floor: 8,
			},
			{
				id: 'Слой_35',
				rooms: 3,
				price: 7,
				available: true,
				surface: 183,
				floor: 7,
			},
			{
				id: 'Слой_36',
				rooms: 4,
				price: 8,
				available: true,
				surface: 173,
				floor: 7,
			},
			{
				id: 'Слой_37',
				rooms: 5,
				price: 9,
				available: true,
				surface: 157,
				floor: 6,
			},
			{
				id: 'Слой_38',
				rooms: 1,
				price: 10,
				available: false,
				surface: 83,
				floor: 6,
			},
			{
				id: 'Слой_39',
				rooms: 2,
				price: 5,
				available: true,
				surface: 123,
				floor: 5,
			},
			{
				id: 'Слой_40',
				rooms: 3,
				price: 6,
				available: true,
				surface: 163,
				floor: 5,
			},
			{
				id: 'Слой_41',
				rooms: 4,
				price: 7,
				available: true,
				surface: 173,
				floor: 4,
			},
			{
				id: 'Слой_42',
				rooms: 5,
				price: 8,
				available: true,
				surface: 157,
				floor: 4,
			},
			{
				id: 'Слой_43',
				rooms: 1,
				price: 9,
				available: false,
				surface: 93,
				floor: 3,
			},
			{
				id: 'Слой_44',
				rooms: 1,
				price: 10,
				available: false,
				surface: 103,
				floor: 3,
			},
			{
				id: 'Слой_45',
				rooms: 1,
				price: 5,
				available: false,
				surface: 113,
				floor: 2,
			},
			{
				id: 'Слой_46',
				rooms: 1,
				price: 6,
				available: false,
				surface: 123,
				floor: 2,
			},
			{
				id: 'Слой_47',
				rooms: 1,
				price: 7,
				available: false,
				surface: 133,
				floor: 1,
			},
			{
				id: 'Слой_48',
				rooms: 1,
				price: 8,
				available: false,
				surface: 143,
				floor: 1,
			},
		],
	},
	{
		id: 'Demo/svg/2.svg',
		elements: [
			{
				id: 'Слой_5_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 133,
				floor: 8,
			},
			{
				id: 'Слой_6_',
				rooms: 1,
				price: 6,
				available: false,
				surface: 143,
				floor: 8,
			},
			{
				id: 'Слой_7_',
				rooms: 5,
				price: 7,
				available: false,
				surface: 240,
				floor: 7,
			},
			{
				id: 'Слой_8_',
				rooms: 4,
				price: 8,
				available: true,
				surface: 182,
				floor: 7,
			},
			{
				id: 'Слой_9_',
				rooms: 2,
				price: 9,
				available: true,
				surface: 108,
				floor: 6,
			},
			{
				id: 'Слой_10_',
				rooms: 3,
				price: 10,
				available: false,
				surface: 210,
				floor: 5,
			},
			{
				id: 'Слой_11_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 71,
				floor: 4,
			},
			{
				id: 'Слой_12_',
				rooms: 3,
				price: 6,
				available: true,
				surface: 158,
				floor: 3,
			},
			{
				id: 'Слой_13_',
				rooms: 1,
				price: 7,
				available: true,
				surface: 86,
				floor: 2,
			},
			{
				id: 'Слой_14_',
				rooms: 5,
				price: 8,
				available: false,
				surface: 219,
				floor: 1,
			},
			{
				id: 'Слой_15_',
				rooms: 2,
				price: 9,
				available: true,
				surface: 123,
				floor: 1,
			},
			{
				id: 'Слой_16_',
				rooms: 4,
				price: 10,
				available: true,
				surface: 170,
				floor: 2,
			},
			{
				id: 'Слой_17_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 60,
				floor: 3,
			},
			{
				id: 'Слой_18_',
				rooms: 4,
				price: 6,
				available: false,
				surface: 199,
				floor: 4,
			},
			{
				id: 'Слой_19_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 127,
				floor: 5,
			},
			{
				id: 'Слой_20_',
				rooms: 1,
				price: 8,
				available: false,
				surface: 70,
				floor: 6,
			},
			{
				id: 'Слой_21_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 225,
				floor: 10,
			},
			{
				id: 'Слой_22_',
				rooms: 2,
				price: 10,
				available: true,
				surface: 114,
				floor: 9,
			},
			{
				id: 'Слой_23_',
				rooms: 3,
				price: 5,
				available: false,
				surface: 135,
				floor: 8,
			},
			{
				id: 'Слой_24_',
				rooms: 1,
				price: 6,
				available: true,
				surface: 80,
				floor: 7,
			},
			{
				id: 'Слой_25_',
				rooms: 4,
				price: 7,
				available: false,
				surface: 177,
				floor: 6,
			},
			{
				id: 'Слой_26_',
				rooms: 2,
				price: 8,
				available: true,
				surface: 103,
				floor: 5,
			},
			{
				id: 'Слой_27_',
				rooms: 3,
				price: 9,
				available: true,
				surface: 140,
				floor: 4,
			},
			{
				id: 'Слой_28_',
				rooms: 5,
				price: 10,
				available: false,
				surface: 233,
				floor: 3,
			},
			{
				id: 'Слой_29_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 66,
				floor: 2,
			},
			{
				id: 'Слой_30_',
				rooms: 5,
				price: 6,
				available: false,
				surface: 203,
				floor: 1,
			},
			{
				id: 'Слой_31_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 124,
				floor: 1,
			},
			{
				id: 'Слой_32_',
				rooms: 1,
				price: 8,
				available: false,
				surface: 75,
				floor: 2,
			},
			{
				id: 'Слой_33_',
				rooms: 4,
				price: 9,
				available: false,
				surface: 186,
				floor: 3,
			},
			{
				id: 'Слой_34_',
				rooms: 2,
				price: 10,
				available: true,
				surface: 110,
				floor: 4,
			},
			{
				id: 'Слой_35_',
				rooms: 3,
				price: 5,
				available: true,
				surface: 130,
				floor: 5,
			},
			{
				id: 'Слой_36_',
				rooms: 1,
				price: 6,
				available: false,
				surface: 78,
				floor: 6,
			},
			{
				id: 'Слой_37_',
				rooms: 5,
				price: 7,
				available: false,
				surface: 227,
				floor: 7,
			},
			{
				id: 'Слой_38_',
				rooms: 2,
				price: 8,
				available: true,
				surface: 115,
				floor: 8,
			},
			{
				id: 'Слой_39_',
				rooms: 3,
				price: 9,
				available: true,
				surface: 137,
				floor: 9,
			},
			{
				id: 'Слой_40_',
				rooms: 4,
				price: 10,
				available: false,
				surface: 195,
				floor: 10,
			},
		],
	},
	{
		id: 'Demo/svg/3.svg',
		elements: [
			{
				id: 'Слой_4_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 60,
				floor: 8,
			},
			{
				id: 'Слой_5_',
				rooms: 2,
				price: 6,
				available: false,
				surface: 80,
				floor: 7,
			},
			{
				id: 'Слой_6_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 100,
				floor: 6,
			},
			{
				id: 'Слой_7_',
				rooms: 4,
				price: 8,
				available: false,
				surface: 120,
				floor: 5,
			},
			{
				id: 'Слой_8_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 140,
				floor: 4,
			},
			{
				id: 'Слой_9_',
				rooms: 1,
				price: 10,
				available: false,
				surface: 70,
				floor: 3,
			},
			{
				id: 'Слой_10_',
				rooms: 2,
				price: 5,
				available: true,
				surface: 90,
				floor: 2,
			},
			{
				id: 'Слой_11_',
				rooms: 3,
				price: 6,
				available: false,
				surface: 110,
				floor: 1,
			},
			{
				id: 'Слой_12_',
				rooms: 4,
				price: 7,
				available: true,
				surface: 130,
				floor: 1,
			},
			{
				id: 'Слой_13_',
				rooms: 5,
				price: 8,
				available: false,
				surface: 150,
				floor: 2,
			},
			{
				id: 'Слой_14_',
				rooms: 1,
				price: 9,
				available: true,
				surface: 65,
				floor: 3,
			},
			{
				id: 'Слой_15_',
				rooms: 2,
				price: 10,
				available: false,
				surface: 85,
				floor: 4,
			},
			{
				id: 'Слой_16_',
				rooms: 3,
				price: 5,
				available: true,
				surface: 105,
				floor: 5,
			},
			{
				id: 'Слой_17_',
				rooms: 4,
				price: 6,
				available: false,
				surface: 125,
				floor: 6,
			},
			{
				id: 'Слой_18_',
				rooms: 5,
				price: 7,
				available: true,
				surface: 145,
				floor: 7,
			},
			{
				id: 'Слой_19_',
				rooms: 1,
				price: 8,
				available: false,
				surface: 75,
				floor: 8,
			},
			{
				id: 'Слой_21_',
				rooms: 3,
				price: 9,
				available: false,
				surface: 115,
				floor: 10,
			},
			{
				id: 'Слой_22_',
				rooms: 4,
				price: 10,
				available: true,
				surface: 135,
				floor: 10,
			},
			{
				id: 'Слой_23_',
				rooms: 5,
				price: 5,
				available: false,
				surface: 155,
				floor: 9,
			},
			{
				id: 'Слой_24_',
				rooms: 1,
				price: 6,
				available: true,
				surface: 70,
				floor: 9,
			},
			{
				id: 'Слой_25_',
				rooms: 2,
				price: 7,
				available: false,
				surface: 90,
				floor: 8,
			},
			{
				id: 'Слой_26_',
				rooms: 3,
				price: 8,
				available: true,
				surface: 110,
				floor: 8,
			},
			{
				id: 'Слой_27_',
				rooms: 4,
				price: 9,
				available: false,
				surface: 130,
				floor: 7,
			},
			{
				id: 'Слой_28_',
				rooms: 5,
				price: 10,
				available: true,
				surface: 150,
				floor: 7,
			},
			{
				id: 'Слой_29_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 80,
				floor: 6,
			},
			{
				id: 'Слой_30_',
				rooms: 2,
				price: 6,
				available: true,
				surface: 100,
				floor: 6,
			},
			{
				id: 'Слой_31_',
				rooms: 3,
				price: 7,
				available: false,
				surface: 120,
				floor: 5,
			},
			{
				id: 'Слой_32_',
				rooms: 4,
				price: 8,
				available: true,
				surface: 140,
				floor: 5,
			},
			{
				id: 'Слой_33_',
				rooms: 5,
				price: 9,
				available: false,
				surface: 160,
				floor: 4,
			},
			{
				id: 'Слой_34_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 75,
				floor: 4,
			},
			{
				id: 'Слой_35_',
				rooms: 2,
				price: 6,
				available: false,
				surface: 95,
				floor: 3,
			},
			{
				id: 'Слой_36_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 115,
				floor: 3,
			},
			{
				id: 'Слой_37_',
				rooms: 4,
				price: 8,
				available: false,
				surface: 135,
				floor: 2,
			},
			{
				id: 'Слой_38_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 155,
				floor: 1,
			},
			{
				id: 'Слой_39_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 80,
				floor: 1,
			},
			{
				id: 'Слой_40_',
				rooms: 2,
				price: 6,
				available: true,
				surface: 100,
				floor: 2,
			},
		],
	},
	{
		id: 'Demo/svg/4.svg',
		elements: [
			{
				id: 'Слой_3_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 60,
				floor: 8,
			},
			{
				id: 'Слой_4_',
				rooms: 2,
				price: 6,
				available: false,
				surface: 80,
				floor: 8,
			},
			{
				id: 'Слой_5_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 100,
				floor: 7,
			},
			{
				id: 'Слой_6_',
				rooms: 4,
				price: 8,
				available: false,
				surface: 120,
				floor: 6,
			},
			{
				id: 'Слой_7_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 140,
				floor: 6,
			},
			{
				id: 'Слой_8_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 70,
				floor: 7,
			},
			{
				id: 'Слой_9_',
				rooms: 2,
				price: 6,
				available: true,
				surface: 90,
				floor: 5,
			},
			{
				id: 'Слой_10_',
				rooms: 3,
				price: 7,
				available: false,
				surface: 110,
				floor: 5,
			},
			{
				id: 'Слой_11_',
				rooms: 4,
				price: 8,
				available: true,
				surface: 130,
				floor: 4,
			},
			{
				id: 'Слой_12_',
				rooms: 5,
				price: 9,
				available: false,
				surface: 150,
				floor: 4,
			},
			{
				id: 'Слой_13_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 60,
				floor: 3,
			},
			{
				id: 'Слой_14_',
				rooms: 2,
				price: 6,
				available: false,
				surface: 80,
				floor: 3,
			},
			{
				id: 'Слой_15_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 100,
				floor: 2,
			},
			{
				id: 'Слой_16_',
				rooms: 4,
				price: 8,
				available: false,
				surface: 120,
				floor: 2,
			},
			{
				id: 'Слой_17_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 140,
				floor: 1,
			},
			{
				id: 'Слой_18_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 70,
				floor: 1,
			},
			{
				id: 'Слой_19_',
				rooms: 2,
				price: 6,
				available: true,
				surface: 90,
				floor: 10,
			},
			{
				id: 'Слой_20_',
				rooms: 3,
				price: 7,
				available: false,
				surface: 110,
				floor: 10,
			},
			{
				id: 'Слой_21_',
				rooms: 4,
				price: 8,
				available: true,
				surface: 130,
				floor: 9,
			},
			{
				id: 'Слой_22_',
				rooms: 5,
				price: 9,
				available: false,
				surface: 150,
				floor: 8,
			},
			{
				id: 'Слой_23_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 60,
				floor: 8,
			},
			{
				id: 'Слой_24_',
				rooms: 2,
				price: 6,
				available: false,
				surface: 80,
				floor: 9,
			},
			{
				id: 'Слой_25_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 100,
				floor: 7,
			},
			{
				id: 'Слой_26_',
				rooms: 4,
				price: 8,
				available: false,
				surface: 120,
				floor: 7,
			},
			{
				id: 'Слой_27_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 140,
				floor: 6,
			},
			{
				id: 'Слой_28_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 70,
				floor: 6,
			},
			{
				id: 'Слой_29_',
				rooms: 2,
				price: 6,
				available: true,
				surface: 90,
				floor: 5,
			},
			{
				id: 'Слой_30_',
				rooms: 3,
				price: 7,
				available: false,
				surface: 110,
				floor: 5,
			},
			{
				id: 'Слой_31_',
				rooms: 4,
				price: 8,
				available: true,
				surface: 130,
				floor: 4,
			},
			{
				id: 'Слой_32_',
				rooms: 5,
				price: 9,
				available: false,
				surface: 150,
				floor: 4,
			},
			{
				id: 'Слой_33_',
				rooms: 1,
				price: 5,
				available: true,
				surface: 60,
				floor: 3,
			},
			{
				id: 'Слой_34_',
				rooms: 2,
				price: 6,
				available: false,
				surface: 80,
				floor: 3,
			},
			{
				id: 'Слой_35_',
				rooms: 3,
				price: 7,
				available: true,
				surface: 100,
				floor: 2,
			},
			{
				id: 'Слой_36_',
				rooms: 4,
				price: 8,
				available: false,
				surface: 120,
				floor: 2,
			},
			{
				id: 'Слой_37_',
				rooms: 5,
				price: 9,
				available: true,
				surface: 140,
				floor: 1,
			},
			{
				id: 'Слой_38_',
				rooms: 1,
				price: 5,
				available: false,
				surface: 70,
				floor: 1,
			},
		],
	},
]
